@font-face {
    font-family: 'CorporateS';
    src: url('./CorporateSRegular.otf') format('opentype');
    font-display: swap;
}

@font-face {
    font-family: "Corporate A Condensed W07 Rg";
    src: url("https://db.onlinewebfonts.com/t/b949b9c3a129e15bb2c7f315b58fd959.eot");
    src: url("https://db.onlinewebfonts.com/t/b949b9c3a129e15bb2c7f315b58fd959.eot?#iefix")format("embedded-opentype"),
    url("https://db.onlinewebfonts.com/t/b949b9c3a129e15bb2c7f315b58fd959.woff2")format("woff2"),
    url("https://db.onlinewebfonts.com/t/b949b9c3a129e15bb2c7f315b58fd959.woff")format("woff"),
    url("https://db.onlinewebfonts.com/t/b949b9c3a129e15bb2c7f315b58fd959.ttf")format("truetype"),
    url("https://db.onlinewebfonts.com/t/b949b9c3a129e15bb2c7f315b58fd959.svg#Corporate A Condensed W07 Rg")format("svg");
    font-display: swap;
}

@font-face {
    font-family: 'CorporateSDemi';
    src: url('./CorporateSDemiRegular.otf') format('opentype');
    font-display: swap;
}