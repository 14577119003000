@font-face {
  font-family: CorporateS;
  src: url("CorporateSRegular.081468b5.otf") format("opentype");
  font-display: swap;
}

@font-face {
  font-family: Corporate A Condensed W07 Rg;
  src: url("https://db.onlinewebfonts.com/t/b949b9c3a129e15bb2c7f315b58fd959.eot");
  src: url("https://db.onlinewebfonts.com/t/b949b9c3a129e15bb2c7f315b58fd959.eot?#iefix") format("embedded-opentype"), url("https://db.onlinewebfonts.com/t/b949b9c3a129e15bb2c7f315b58fd959.woff2") format("woff2"), url("https://db.onlinewebfonts.com/t/b949b9c3a129e15bb2c7f315b58fd959.woff") format("woff"), url("https://db.onlinewebfonts.com/t/b949b9c3a129e15bb2c7f315b58fd959.ttf") format("truetype"), url("https://db.onlinewebfonts.com/t/b949b9c3a129e15bb2c7f315b58fd959.svg#Corporate A Condensed W07 Rg") format("svg");
  font-display: swap;
}

@font-face {
  font-family: CorporateSDemi;
  src: url("CorporateSDemiRegular.a8f819da.otf") format("opentype");
  font-display: swap;
}

.v-spinner {
  text-align: center;
}

.v-spinner .v-clip {
  animation: .75s linear infinite both v-clipDelay;
  display: inline-block;
}

@keyframes v-clipDelay {
  0% {
    transform: rotate(0)scale(1);
  }

  50% {
    transform: rotate(180deg)scale(.8);
  }

  100% {
    transform: rotate(360deg)scale(1);
  }
}

.multiselect {
  background: var(--ms-bg, #fff);
  border: var(--ms-border-width, 1px) solid var(--ms-border-color, #d1d5db);
  border-radius: var(--ms-radius, 4px);
  box-sizing: border-box;
  cursor: pointer;
  font-size: var(--ms-font-size, 1rem);
  min-height: calc(var(--ms-border-width, 1px) * 2 + var(--ms-font-size, 1rem) * var(--ms-line-height, 1.375)  + var(--ms-py, .5rem) * 2);
  outline: none;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  margin: 0 auto;
  display: flex;
  position: relative;
}

.multiselect.is-open {
  border-radius: var(--ms-radius, 4px) var(--ms-radius, 4px) 0 0;
}

.multiselect.is-open-top {
  border-radius: 0 0 var(--ms-radius, 4px) var(--ms-radius, 4px);
}

.multiselect.is-disabled {
  background: var(--ms-bg-disabled, #f3f4f6);
  cursor: default;
}

.multiselect.is-active {
  border: var(--ms-border-width-active, var(--ms-border-width, 1px)) solid var(--ms-border-color-active, var(--ms-border-color, #d1d5db));
  box-shadow: 0 0 0 var(--ms-ring-width, 3px) var(--ms-ring-color, #10b98130);
}

.multiselect-wrapper {
  box-sizing: border-box;
  cursor: pointer;
  min-height: calc(var(--ms-border-width, 1px) * 2 + var(--ms-font-size, 1rem) * var(--ms-line-height, 1.375)  + var(--ms-py, .5rem) * 2);
  outline: none;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  margin: 0 auto;
  display: flex;
  position: relative;
}

.multiselect-multiple-label, .multiselect-placeholder, .multiselect-single-label {
  box-sizing: border-box;
  line-height: var(--ms-line-height, 1.375);
  padding-left: var(--ms-px, .875rem);
  padding-right: calc(1.25rem + var(--ms-px, .875rem) * 3);
  pointer-events: none;
  background: none;
  align-items: center;
  max-width: 100%;
  height: 100%;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
}

.multiselect-placeholder {
  color: var(--ms-placeholder-color, #9ca3af);
}

.multiselect-single-label-text {
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 100%;
  display: block;
  overflow: hidden;
}

.multiselect-search {
  appearance: none;
  background: var(--ms-bg, #fff);
  border-radius: var(--ms-radius, 4px);
  box-sizing: border-box;
  font-family: inherit;
  font-size: inherit;
  padding-left: var(--ms-px, .875rem);
  border: 0;
  outline: none;
  width: 100%;
  height: 100%;
  position: absolute;
  inset: 0;
}

.multiselect-search::-webkit-search-cancel-button {
  -webkit-appearance: none;
}

.multiselect-search::-webkit-search-decoration {
  -webkit-appearance: none;
}

.multiselect-search::-webkit-search-results-button {
  -webkit-appearance: none;
}

.multiselect-search::-webkit-search-results-decoration {
  -webkit-appearance: none;
}

.multiselect-tags {
  margin: var(--ms-tag-my, .25rem) 0 0;
  padding-left: var(--ms-py, .5rem);
  flex-wrap: wrap;
  flex-grow: 1;
  flex-shrink: 1;
}

.multiselect-tag, .multiselect-tags {
  align-items: center;
  min-width: 0;
  display: flex;
}

.multiselect-tag {
  background: var(--ms-tag-bg, #10b981);
  border-radius: var(--ms-tag-radius, 4px);
  color: var(--ms-tag-color, #fff);
  font-size: var(--ms-tag-font-size, .875rem);
  font-weight: var(--ms-tag-font-weight, 600);
  line-height: var(--ms-tag-line-height, 1.25rem);
  margin-bottom: var(--ms-tag-my, .25rem);
  margin-right: var(--ms-tag-mx, .25rem);
  padding: var(--ms-tag-py, .125rem) 0 var(--ms-tag-py, .125rem) var(--ms-tag-px, .5rem);
  white-space: nowrap;
}

.multiselect-tag.is-disabled {
  background: var(--ms-tag-bg-disabled, #9ca3af);
  color: var(--ms-tag-color-disabled, #fff);
  padding-right: var(--ms-tag-px, .5rem);
}

.multiselect-tag-wrapper {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.multiselect-tag-wrapper-break {
  white-space: normal;
  word-break: break-all;
}

.multiselect-tag-remove {
  border-radius: var(--ms-tag-remove-radius, 4px);
  margin: var(--ms-tag-remove-my, 0) var(--ms-tag-remove-mx, .125rem);
  padding: var(--ms-tag-remove-py, .25rem) var(--ms-tag-remove-px, .25rem);
  justify-content: center;
  align-items: center;
  display: flex;
}

.multiselect-tag-remove:hover {
  background: #00000010;
}

.multiselect-tag-remove-icon {
  opacity: .8;
  background-color: currentColor;
  width: .75rem;
  height: .75rem;
  display: inline-block;
  -webkit-mask-image: url("data:image/svg+xml;charset=utf-8,%3Csvg viewBox='0 0 320 512' fill='currentColor' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='m207.6 256 107.72-107.72c6.23-6.23 6.23-16.34 0-22.58l-25.03-25.03c-6.23-6.23-16.34-6.23-22.58 0L160 208.4 52.28 100.68c-6.23-6.23-16.34-6.23-22.58 0L4.68 125.7c-6.23 6.23-6.23 16.34 0 22.58L112.4 256 4.68 363.72c-6.23 6.23-6.23 16.34 0 22.58l25.03 25.03c6.23 6.23 16.34 6.23 22.58 0L160 303.6l107.72 107.72c6.23 6.23 16.34 6.23 22.58 0l25.03-25.03c6.23-6.23 6.23-16.34 0-22.58L207.6 256z'/%3E%3C/svg%3E");
  mask-image: url("data:image/svg+xml;charset=utf-8,%3Csvg viewBox='0 0 320 512' fill='currentColor' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='m207.6 256 107.72-107.72c6.23-6.23 6.23-16.34 0-22.58l-25.03-25.03c-6.23-6.23-16.34-6.23-22.58 0L160 208.4 52.28 100.68c-6.23-6.23-16.34-6.23-22.58 0L4.68 125.7c-6.23 6.23-6.23 16.34 0 22.58L112.4 256 4.68 363.72c-6.23 6.23-6.23 16.34 0 22.58l25.03 25.03c6.23 6.23 16.34 6.23 22.58 0L160 303.6l107.72 107.72c6.23 6.23 16.34 6.23 22.58 0l25.03-25.03c6.23-6.23 6.23-16.34 0-22.58L207.6 256z'/%3E%3C/svg%3E");
  -webkit-mask-position: center;
  mask-position: center;
  -webkit-mask-size: contain;
  mask-size: contain;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
}

.multiselect-tags-search-wrapper {
  margin: 0 var(--ms-tag-mx, 4px) var(--ms-tag-my, 4px);
  flex-grow: 1;
  flex-shrink: 1;
  height: 100%;
  display: inline-block;
  position: relative;
}

.multiselect-tags-search-copy {
  visibility: hidden;
  white-space: pre-wrap;
  width: 100%;
  height: 1px;
  display: inline-block;
}

.multiselect-tags-search {
  appearance: none;
  box-sizing: border-box;
  font-family: inherit;
  font-size: inherit;
  border: 0;
  outline: none;
  width: 100%;
  padding: 0;
  position: absolute;
  inset: 0;
}

.multiselect-tags-search::-webkit-search-cancel-button {
  -webkit-appearance: none;
}

.multiselect-tags-search::-webkit-search-decoration {
  -webkit-appearance: none;
}

.multiselect-tags-search::-webkit-search-results-button {
  -webkit-appearance: none;
}

.multiselect-tags-search::-webkit-search-results-decoration {
  -webkit-appearance: none;
}

.multiselect-inifite {
  min-height: calc(var(--ms-border-width, 1px) * 2 + var(--ms-font-size, 1rem) * var(--ms-line-height, 1.375)  + var(--ms-py, .5rem) * 2);
  justify-content: center;
  align-items: center;
  width: 100%;
  display: flex;
}

.multiselect-inifite-spinner, .multiselect-spinner {
  background-color: var(--ms-spinner-color, #10b981);
  z-index: 10;
  flex-grow: 0;
  flex-shrink: 0;
  width: 1rem;
  height: 1rem;
  animation: 1s linear infinite multiselect-spin;
  -webkit-mask-image: url("data:image/svg+xml;charset=utf-8,%3Csvg viewBox='0 0 512 512' fill='currentColor' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='m456.433 371.72-27.79-16.045c-7.192-4.152-10.052-13.136-6.487-20.636 25.82-54.328 23.566-118.602-6.768-171.03-30.265-52.529-84.802-86.621-144.76-91.424C262.35 71.922 256 64.953 256 56.649V24.56c0-9.31 7.916-16.609 17.204-15.96 81.795 5.717 156.412 51.902 197.611 123.408 41.301 71.385 43.99 159.096 8.042 232.792-4.082 8.369-14.361 11.575-22.424 6.92z'/%3E%3C/svg%3E");
  mask-image: url("data:image/svg+xml;charset=utf-8,%3Csvg viewBox='0 0 512 512' fill='currentColor' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='m456.433 371.72-27.79-16.045c-7.192-4.152-10.052-13.136-6.487-20.636 25.82-54.328 23.566-118.602-6.768-171.03-30.265-52.529-84.802-86.621-144.76-91.424C262.35 71.922 256 64.953 256 56.649V24.56c0-9.31 7.916-16.609 17.204-15.96 81.795 5.717 156.412 51.902 197.611 123.408 41.301 71.385 43.99 159.096 8.042 232.792-4.082 8.369-14.361 11.575-22.424 6.92z'/%3E%3C/svg%3E");
  -webkit-mask-position: center;
  mask-position: center;
  -webkit-mask-size: contain;
  mask-size: contain;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
}

.multiselect-spinner {
  margin: 0 var(--ms-px, .875rem) 0 0;
}

.multiselect-clear {
  opacity: 1;
  padding: 0 var(--ms-px, .875rem) 0 0;
  z-index: 10;
  flex-grow: 0;
  flex-shrink: 0;
  transition: all .3s;
  display: flex;
  position: relative;
}

.multiselect-clear:hover .multiselect-clear-icon {
  background-color: var(--ms-clear-color-hover, #000);
}

.multiselect-clear-icon {
  background-color: var(--ms-clear-color, #999);
  transition: all .3s;
  display: inline-block;
  -webkit-mask-image: url("data:image/svg+xml;charset=utf-8,%3Csvg viewBox='0 0 320 512' fill='currentColor' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='m207.6 256 107.72-107.72c6.23-6.23 6.23-16.34 0-22.58l-25.03-25.03c-6.23-6.23-16.34-6.23-22.58 0L160 208.4 52.28 100.68c-6.23-6.23-16.34-6.23-22.58 0L4.68 125.7c-6.23 6.23-6.23 16.34 0 22.58L112.4 256 4.68 363.72c-6.23 6.23-6.23 16.34 0 22.58l25.03 25.03c6.23 6.23 16.34 6.23 22.58 0L160 303.6l107.72 107.72c6.23 6.23 16.34 6.23 22.58 0l25.03-25.03c6.23-6.23 6.23-16.34 0-22.58L207.6 256z'/%3E%3C/svg%3E");
  mask-image: url("data:image/svg+xml;charset=utf-8,%3Csvg viewBox='0 0 320 512' fill='currentColor' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='m207.6 256 107.72-107.72c6.23-6.23 6.23-16.34 0-22.58l-25.03-25.03c-6.23-6.23-16.34-6.23-22.58 0L160 208.4 52.28 100.68c-6.23-6.23-16.34-6.23-22.58 0L4.68 125.7c-6.23 6.23-6.23 16.34 0 22.58L112.4 256 4.68 363.72c-6.23 6.23-6.23 16.34 0 22.58l25.03 25.03c6.23 6.23 16.34 6.23 22.58 0L160 303.6l107.72 107.72c6.23 6.23 16.34 6.23 22.58 0l25.03-25.03c6.23-6.23 6.23-16.34 0-22.58L207.6 256z'/%3E%3C/svg%3E");
}

.multiselect-caret, .multiselect-clear-icon {
  width: .625rem;
  height: 1.125rem;
  -webkit-mask-position: center;
  mask-position: center;
  -webkit-mask-size: contain;
  mask-size: contain;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
}

.multiselect-caret {
  background-color: var(--ms-caret-color, #999);
  margin: 0 var(--ms-px, .875rem) 0 0;
  pointer-events: none;
  z-index: 10;
  flex-grow: 0;
  flex-shrink: 0;
  transition: transform .3s;
  position: relative;
  transform: rotate(0);
  -webkit-mask-image: url("data:image/svg+xml;charset=utf-8,%3Csvg viewBox='0 0 320 512' fill='currentColor' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M31.3 192h257.3c17.8 0 26.7 21.5 14.1 34.1L174.1 354.8c-7.8 7.8-20.5 7.8-28.3 0L17.2 226.1C4.6 213.5 13.5 192 31.3 192z'/%3E%3C/svg%3E");
  mask-image: url("data:image/svg+xml;charset=utf-8,%3Csvg viewBox='0 0 320 512' fill='currentColor' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M31.3 192h257.3c17.8 0 26.7 21.5 14.1 34.1L174.1 354.8c-7.8 7.8-20.5 7.8-28.3 0L17.2 226.1C4.6 213.5 13.5 192 31.3 192z'/%3E%3C/svg%3E");
}

.multiselect-caret.is-open {
  pointer-events: auto;
  transform: rotate(180deg);
}

.multiselect-dropdown {
  -webkit-overflow-scrolling: touch;
  background: var(--ms-dropdown-bg, #fff);
  border: var(--ms-dropdown-border-width, 1px) solid var(--ms-dropdown-border-color, #d1d5db);
  border-radius: 0 0 var(--ms-dropdown-radius, 4px) var(--ms-dropdown-radius, 4px);
  bottom: 0;
  left: calc(var(--ms-border-width, 1px) * -1);
  margin-top: calc(var(--ms-border-width, 1px) * -1);
  max-height: var(--ms-max-height, 10rem);
  right: calc(var(--ms-border-width, 1px) * -1);
  z-index: 100;
  outline: none;
  flex-direction: column;
  display: flex;
  position: absolute;
  overflow-y: scroll;
  transform: translateY(100%);
}

.multiselect-dropdown.is-top {
  border-radius: var(--ms-dropdown-radius, 4px) var(--ms-dropdown-radius, 4px) 0 0;
  bottom: auto;
  top: var(--ms-border-width, 1px);
  transform: translateY(-100%);
}

.multiselect-dropdown.is-hidden {
  display: none;
}

.multiselect-options {
  flex-direction: column;
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
}

.multiselect-group {
  margin: 0;
  padding: 0;
}

.multiselect-group-label {
  background: var(--ms-group-label-bg, #e5e7eb);
  box-sizing: border-box;
  color: var(--ms-group-label-color, #374151);
  cursor: default;
  font-size: .875rem;
  font-weight: 600;
  line-height: var(--ms-group-label-line-height, 1.375);
  padding: var(--ms-group-label-py, .3rem) var(--ms-group-label-px, .75rem);
  text-align: left;
  justify-content: flex-start;
  align-items: center;
  text-decoration: none;
  display: flex;
}

.multiselect-group-label.is-pointable {
  cursor: pointer;
}

.multiselect-group-label.is-pointed {
  background: var(--ms-group-label-bg-pointed, #d1d5db);
  color: var(--ms-group-label-color-pointed, #374151);
}

.multiselect-group-label.is-selected {
  background: var(--ms-group-label-bg-selected, #059669);
  color: var(--ms-group-label-color-selected, #fff);
}

.multiselect-group-label.is-disabled {
  background: var(--ms-group-label-bg-disabled, #f3f4f6);
  color: var(--ms-group-label-color-disabled, #d1d5db);
  cursor: not-allowed;
}

.multiselect-group-label.is-selected.is-pointed {
  background: var(--ms-group-label-bg-selected-pointed, #0c9e70);
  color: var(--ms-group-label-color-selected-pointed, #fff);
}

.multiselect-group-label.is-selected.is-disabled {
  background: var(--ms-group-label-bg-selected-disabled, #75cfb1);
  color: var(--ms-group-label-color-selected-disabled, #d1fae5);
}

.multiselect-group-options {
  margin: 0;
  padding: 0;
}

.multiselect-option {
  box-sizing: border-box;
  cursor: pointer;
  font-size: var(--ms-option-font-size, 1rem);
  line-height: var(--ms-option-line-height, 1.375);
  padding: var(--ms-option-py, .5rem) var(--ms-option-px, .75rem);
  text-align: left;
  justify-content: flex-start;
  align-items: center;
  text-decoration: none;
  display: flex;
}

.multiselect-option.is-pointed {
  background: var(--ms-option-bg-pointed, #f3f4f6);
  color: var(--ms-option-color-pointed, #1f2937);
}

.multiselect-option.is-selected {
  background: var(--ms-option-bg-selected, #10b981);
  color: var(--ms-option-color-selected, #fff);
}

.multiselect-option.is-disabled {
  background: var(--ms-option-bg-disabled, #fff);
  color: var(--ms-option-color-disabled, #d1d5db);
  cursor: not-allowed;
}

.multiselect-option.is-selected.is-pointed {
  background: var(--ms-option-bg-selected-pointed, #26c08e);
  color: var(--ms-option-color-selected-pointed, #fff);
}

.multiselect-option.is-selected.is-disabled {
  background: var(--ms-option-bg-selected-disabled, #87dcc0);
  color: var(--ms-option-color-selected-disabled, #d1fae5);
}

.multiselect-no-options, .multiselect-no-results {
  color: var(--ms-empty-color, #4b5563);
  padding: var(--ms-option-py, .5rem) var(--ms-option-px, .75rem);
}

.multiselect-fake-input {
  background: none;
  border: 0;
  outline: none;
  width: 100%;
  height: 1px;
  padding: 0;
  font-size: 0;
  position: absolute;
  bottom: -1px;
  left: 0;
  right: 0;
}

.multiselect-fake-input:active, .multiselect-fake-input:focus {
  outline: none;
}

.multiselect-assistive-text {
  clip: rect(0 0 0 0);
  width: 1px;
  height: 1px;
  margin: -1px;
  position: absolute;
  overflow: hidden;
}

.multiselect-spacer {
  display: none;
}

[dir="rtl"] .multiselect-multiple-label, [dir="rtl"] .multiselect-placeholder, [dir="rtl"] .multiselect-single-label {
  padding-left: calc(1.25rem + var(--ms-px, .875rem) * 3);
  padding-right: var(--ms-px, .875rem);
  left: auto;
  right: 0;
}

[dir="rtl"] .multiselect-search {
  padding-left: 0;
  padding-right: var(--ms-px, .875rem);
}

[dir="rtl"] .multiselect-tags {
  padding-left: 0;
  padding-right: var(--ms-py, .5rem);
}

[dir="rtl"] .multiselect-tag {
  margin-left: var(--ms-tag-mx, .25rem);
  padding: var(--ms-tag-py, .125rem) var(--ms-tag-px, .5rem) var(--ms-tag-py, .125rem) 0;
  margin-right: 0;
}

[dir="rtl"] .multiselect-tag.is-disabled {
  padding-left: var(--ms-tag-px, .5rem);
}

[dir="rtl"] .multiselect-caret, [dir="rtl"] .multiselect-spinner {
  margin: 0 0 0 var(--ms-px, .875rem);
}

[dir="rtl"] .multiselect-clear {
  padding: 0 0 0 var(--ms-px, .875rem);
}

@keyframes multiselect-spin {
  0% {
    transform: rotate(0);
  }

  to {
    transform: rotate(1turn);
  }
}

.multiselect {
  background: var(--ms-bg, #fff);
  border: var(--ms-border-width, 1px) solid var(--ms-border-color, #d1d5db);
  border-radius: var(--ms-radius, 4px);
  box-sizing: border-box;
  cursor: pointer;
  font-size: var(--ms-font-size, 1rem);
  min-height: calc(var(--ms-border-width, 1px) * 2 + var(--ms-font-size, 1rem) * var(--ms-line-height, 1.375)  + var(--ms-py, .5rem) * 2);
  outline: none;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  margin: 0 auto;
  display: flex;
  position: relative;
}

.multiselect.is-open {
  border-radius: var(--ms-radius, 4px) var(--ms-radius, 4px) 0 0;
}

.multiselect.is-open-top {
  border-radius: 0 0 var(--ms-radius, 4px) var(--ms-radius, 4px);
}

.multiselect.is-disabled {
  background: var(--ms-bg-disabled, #f3f4f6);
  cursor: default;
}

.multiselect.is-active {
  border: var(--ms-border-width-active, var(--ms-border-width, 1px)) solid var(--ms-border-color-active, var(--ms-border-color, #d1d5db));
  box-shadow: 0 0 0 var(--ms-ring-width, 3px) var(--ms-ring-color, #10b98130);
}

.multiselect-wrapper {
  box-sizing: border-box;
  cursor: pointer;
  min-height: calc(var(--ms-border-width, 1px) * 2 + var(--ms-font-size, 1rem) * var(--ms-line-height, 1.375)  + var(--ms-py, .5rem) * 2);
  outline: none;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  margin: 0 auto;
  display: flex;
  position: relative;
}

.multiselect-multiple-label, .multiselect-placeholder, .multiselect-single-label {
  box-sizing: border-box;
  line-height: var(--ms-line-height, 1.375);
  padding-left: var(--ms-px, .875rem);
  padding-right: calc(1.25rem + var(--ms-px, .875rem) * 3);
  pointer-events: none;
  background: none;
  align-items: center;
  max-width: 100%;
  height: 100%;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
}

.multiselect-placeholder {
  color: var(--ms-placeholder-color, #9ca3af);
}

.multiselect-single-label-text {
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 100%;
  display: block;
  overflow: hidden;
}

.multiselect-search {
  appearance: none;
  background: var(--ms-bg, #fff);
  border-radius: var(--ms-radius, 4px);
  box-sizing: border-box;
  font-family: inherit;
  font-size: inherit;
  padding-left: var(--ms-px, .875rem);
  border: 0;
  outline: none;
  width: 100%;
  height: 100%;
  position: absolute;
  inset: 0;
}

.multiselect-search::-webkit-search-cancel-button {
  -webkit-appearance: none;
}

.multiselect-search::-webkit-search-decoration {
  -webkit-appearance: none;
}

.multiselect-search::-webkit-search-results-button {
  -webkit-appearance: none;
}

.multiselect-search::-webkit-search-results-decoration {
  -webkit-appearance: none;
}

.multiselect-tags {
  margin: var(--ms-tag-my, .25rem) 0 0;
  padding-left: var(--ms-py, .5rem);
  flex-wrap: wrap;
  flex-grow: 1;
  flex-shrink: 1;
}

.multiselect-tag, .multiselect-tags {
  align-items: center;
  min-width: 0;
  display: flex;
}

.multiselect-tag {
  background: var(--ms-tag-bg, #10b981);
  border-radius: var(--ms-tag-radius, 4px);
  color: var(--ms-tag-color, #fff);
  font-size: var(--ms-tag-font-size, .875rem);
  font-weight: var(--ms-tag-font-weight, 600);
  line-height: var(--ms-tag-line-height, 1.25rem);
  margin-bottom: var(--ms-tag-my, .25rem);
  margin-right: var(--ms-tag-mx, .25rem);
  padding: var(--ms-tag-py, .125rem) 0 var(--ms-tag-py, .125rem) var(--ms-tag-px, .5rem);
  white-space: nowrap;
}

.multiselect-tag.is-disabled {
  background: var(--ms-tag-bg-disabled, #9ca3af);
  color: var(--ms-tag-color-disabled, #fff);
  padding-right: var(--ms-tag-px, .5rem);
}

.multiselect-tag-wrapper {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.multiselect-tag-wrapper-break {
  white-space: normal;
  word-break: break-all;
}

.multiselect-tag-remove {
  border-radius: var(--ms-tag-remove-radius, 4px);
  margin: var(--ms-tag-remove-my, 0) var(--ms-tag-remove-mx, .125rem);
  padding: var(--ms-tag-remove-py, .25rem) var(--ms-tag-remove-px, .25rem);
  justify-content: center;
  align-items: center;
  display: flex;
}

.multiselect-tag-remove:hover {
  background: #00000010;
}

.multiselect-tag-remove-icon {
  opacity: .8;
  background-color: currentColor;
  width: .75rem;
  height: .75rem;
  display: inline-block;
  -webkit-mask-image: url("data:image/svg+xml;charset=utf-8,%3Csvg viewBox='0 0 320 512' fill='currentColor' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='m207.6 256 107.72-107.72c6.23-6.23 6.23-16.34 0-22.58l-25.03-25.03c-6.23-6.23-16.34-6.23-22.58 0L160 208.4 52.28 100.68c-6.23-6.23-16.34-6.23-22.58 0L4.68 125.7c-6.23 6.23-6.23 16.34 0 22.58L112.4 256 4.68 363.72c-6.23 6.23-6.23 16.34 0 22.58l25.03 25.03c6.23 6.23 16.34 6.23 22.58 0L160 303.6l107.72 107.72c6.23 6.23 16.34 6.23 22.58 0l25.03-25.03c6.23-6.23 6.23-16.34 0-22.58L207.6 256z'/%3E%3C/svg%3E");
  mask-image: url("data:image/svg+xml;charset=utf-8,%3Csvg viewBox='0 0 320 512' fill='currentColor' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='m207.6 256 107.72-107.72c6.23-6.23 6.23-16.34 0-22.58l-25.03-25.03c-6.23-6.23-16.34-6.23-22.58 0L160 208.4 52.28 100.68c-6.23-6.23-16.34-6.23-22.58 0L4.68 125.7c-6.23 6.23-6.23 16.34 0 22.58L112.4 256 4.68 363.72c-6.23 6.23-6.23 16.34 0 22.58l25.03 25.03c6.23 6.23 16.34 6.23 22.58 0L160 303.6l107.72 107.72c6.23 6.23 16.34 6.23 22.58 0l25.03-25.03c6.23-6.23 6.23-16.34 0-22.58L207.6 256z'/%3E%3C/svg%3E");
  -webkit-mask-position: center;
  mask-position: center;
  -webkit-mask-size: contain;
  mask-size: contain;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
}

.multiselect-tags-search-wrapper {
  margin: 0 var(--ms-tag-mx, 4px) var(--ms-tag-my, 4px);
  flex-grow: 1;
  flex-shrink: 1;
  height: 100%;
  display: inline-block;
  position: relative;
}

.multiselect-tags-search-copy {
  visibility: hidden;
  white-space: pre-wrap;
  width: 100%;
  height: 1px;
  display: inline-block;
}

.multiselect-tags-search {
  appearance: none;
  box-sizing: border-box;
  font-family: inherit;
  font-size: inherit;
  border: 0;
  outline: none;
  width: 100%;
  padding: 0;
  position: absolute;
  inset: 0;
}

.multiselect-tags-search::-webkit-search-cancel-button {
  -webkit-appearance: none;
}

.multiselect-tags-search::-webkit-search-decoration {
  -webkit-appearance: none;
}

.multiselect-tags-search::-webkit-search-results-button {
  -webkit-appearance: none;
}

.multiselect-tags-search::-webkit-search-results-decoration {
  -webkit-appearance: none;
}

.multiselect-inifite {
  min-height: calc(var(--ms-border-width, 1px) * 2 + var(--ms-font-size, 1rem) * var(--ms-line-height, 1.375)  + var(--ms-py, .5rem) * 2);
  justify-content: center;
  align-items: center;
  width: 100%;
  display: flex;
}

.multiselect-inifite-spinner, .multiselect-spinner {
  background-color: var(--ms-spinner-color, #10b981);
  z-index: 10;
  flex-grow: 0;
  flex-shrink: 0;
  width: 1rem;
  height: 1rem;
  animation: 1s linear infinite multiselect-spin;
  -webkit-mask-image: url("data:image/svg+xml;charset=utf-8,%3Csvg viewBox='0 0 512 512' fill='currentColor' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='m456.433 371.72-27.79-16.045c-7.192-4.152-10.052-13.136-6.487-20.636 25.82-54.328 23.566-118.602-6.768-171.03-30.265-52.529-84.802-86.621-144.76-91.424C262.35 71.922 256 64.953 256 56.649V24.56c0-9.31 7.916-16.609 17.204-15.96 81.795 5.717 156.412 51.902 197.611 123.408 41.301 71.385 43.99 159.096 8.042 232.792-4.082 8.369-14.361 11.575-22.424 6.92z'/%3E%3C/svg%3E");
  mask-image: url("data:image/svg+xml;charset=utf-8,%3Csvg viewBox='0 0 512 512' fill='currentColor' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='m456.433 371.72-27.79-16.045c-7.192-4.152-10.052-13.136-6.487-20.636 25.82-54.328 23.566-118.602-6.768-171.03-30.265-52.529-84.802-86.621-144.76-91.424C262.35 71.922 256 64.953 256 56.649V24.56c0-9.31 7.916-16.609 17.204-15.96 81.795 5.717 156.412 51.902 197.611 123.408 41.301 71.385 43.99 159.096 8.042 232.792-4.082 8.369-14.361 11.575-22.424 6.92z'/%3E%3C/svg%3E");
  -webkit-mask-position: center;
  mask-position: center;
  -webkit-mask-size: contain;
  mask-size: contain;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
}

.multiselect-spinner {
  margin: 0 var(--ms-px, .875rem) 0 0;
}

.multiselect-clear {
  opacity: 1;
  padding: 0 var(--ms-px, .875rem) 0 0;
  z-index: 10;
  flex-grow: 0;
  flex-shrink: 0;
  transition: all .3s;
  display: flex;
  position: relative;
}

.multiselect-clear:hover .multiselect-clear-icon {
  background-color: var(--ms-clear-color-hover, #000);
}

.multiselect-clear-icon {
  background-color: var(--ms-clear-color, #999);
  transition: all .3s;
  display: inline-block;
  -webkit-mask-image: url("data:image/svg+xml;charset=utf-8,%3Csvg viewBox='0 0 320 512' fill='currentColor' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='m207.6 256 107.72-107.72c6.23-6.23 6.23-16.34 0-22.58l-25.03-25.03c-6.23-6.23-16.34-6.23-22.58 0L160 208.4 52.28 100.68c-6.23-6.23-16.34-6.23-22.58 0L4.68 125.7c-6.23 6.23-6.23 16.34 0 22.58L112.4 256 4.68 363.72c-6.23 6.23-6.23 16.34 0 22.58l25.03 25.03c6.23 6.23 16.34 6.23 22.58 0L160 303.6l107.72 107.72c6.23 6.23 16.34 6.23 22.58 0l25.03-25.03c6.23-6.23 6.23-16.34 0-22.58L207.6 256z'/%3E%3C/svg%3E");
  mask-image: url("data:image/svg+xml;charset=utf-8,%3Csvg viewBox='0 0 320 512' fill='currentColor' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='m207.6 256 107.72-107.72c6.23-6.23 6.23-16.34 0-22.58l-25.03-25.03c-6.23-6.23-16.34-6.23-22.58 0L160 208.4 52.28 100.68c-6.23-6.23-16.34-6.23-22.58 0L4.68 125.7c-6.23 6.23-6.23 16.34 0 22.58L112.4 256 4.68 363.72c-6.23 6.23-6.23 16.34 0 22.58l25.03 25.03c6.23 6.23 16.34 6.23 22.58 0L160 303.6l107.72 107.72c6.23 6.23 16.34 6.23 22.58 0l25.03-25.03c6.23-6.23 6.23-16.34 0-22.58L207.6 256z'/%3E%3C/svg%3E");
}

.multiselect-caret, .multiselect-clear-icon {
  width: .625rem;
  height: 1.125rem;
  -webkit-mask-position: center;
  mask-position: center;
  -webkit-mask-size: contain;
  mask-size: contain;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
}

.multiselect-caret {
  background-color: var(--ms-caret-color, #999);
  margin: 0 var(--ms-px, .875rem) 0 0;
  pointer-events: none;
  z-index: 10;
  flex-grow: 0;
  flex-shrink: 0;
  transition: transform .3s;
  position: relative;
  transform: rotate(0);
  -webkit-mask-image: url("data:image/svg+xml;charset=utf-8,%3Csvg viewBox='0 0 320 512' fill='currentColor' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M31.3 192h257.3c17.8 0 26.7 21.5 14.1 34.1L174.1 354.8c-7.8 7.8-20.5 7.8-28.3 0L17.2 226.1C4.6 213.5 13.5 192 31.3 192z'/%3E%3C/svg%3E");
  mask-image: url("data:image/svg+xml;charset=utf-8,%3Csvg viewBox='0 0 320 512' fill='currentColor' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M31.3 192h257.3c17.8 0 26.7 21.5 14.1 34.1L174.1 354.8c-7.8 7.8-20.5 7.8-28.3 0L17.2 226.1C4.6 213.5 13.5 192 31.3 192z'/%3E%3C/svg%3E");
}

.multiselect-caret.is-open {
  pointer-events: auto;
  transform: rotate(180deg);
}

.multiselect-dropdown {
  -webkit-overflow-scrolling: touch;
  background: var(--ms-dropdown-bg, #fff);
  border: var(--ms-dropdown-border-width, 1px) solid var(--ms-dropdown-border-color, #d1d5db);
  border-radius: 0 0 var(--ms-dropdown-radius, 4px) var(--ms-dropdown-radius, 4px);
  bottom: 0;
  left: calc(var(--ms-border-width, 1px) * -1);
  margin-top: calc(var(--ms-border-width, 1px) * -1);
  max-height: var(--ms-max-height, 10rem);
  right: calc(var(--ms-border-width, 1px) * -1);
  z-index: 100;
  outline: none;
  flex-direction: column;
  display: flex;
  position: absolute;
  overflow-y: scroll;
  transform: translateY(100%);
}

.multiselect-dropdown.is-top {
  border-radius: var(--ms-dropdown-radius, 4px) var(--ms-dropdown-radius, 4px) 0 0;
  bottom: auto;
  top: var(--ms-border-width, 1px);
  transform: translateY(-100%);
}

.multiselect-dropdown.is-hidden {
  display: none;
}

.multiselect-options {
  flex-direction: column;
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
}

.multiselect-group {
  margin: 0;
  padding: 0;
}

.multiselect-group-label {
  background: var(--ms-group-label-bg, #e5e7eb);
  box-sizing: border-box;
  color: var(--ms-group-label-color, #374151);
  cursor: default;
  font-size: .875rem;
  font-weight: 600;
  line-height: var(--ms-group-label-line-height, 1.375);
  padding: var(--ms-group-label-py, .3rem) var(--ms-group-label-px, .75rem);
  text-align: left;
  justify-content: flex-start;
  align-items: center;
  text-decoration: none;
  display: flex;
}

.multiselect-group-label.is-pointable {
  cursor: pointer;
}

.multiselect-group-label.is-pointed {
  background: var(--ms-group-label-bg-pointed, #d1d5db);
  color: var(--ms-group-label-color-pointed, #374151);
}

.multiselect-group-label.is-selected {
  background: var(--ms-group-label-bg-selected, #059669);
  color: var(--ms-group-label-color-selected, #fff);
}

.multiselect-group-label.is-disabled {
  background: var(--ms-group-label-bg-disabled, #f3f4f6);
  color: var(--ms-group-label-color-disabled, #d1d5db);
  cursor: not-allowed;
}

.multiselect-group-label.is-selected.is-pointed {
  background: var(--ms-group-label-bg-selected-pointed, #0c9e70);
  color: var(--ms-group-label-color-selected-pointed, #fff);
}

.multiselect-group-label.is-selected.is-disabled {
  background: var(--ms-group-label-bg-selected-disabled, #75cfb1);
  color: var(--ms-group-label-color-selected-disabled, #d1fae5);
}

.multiselect-group-options {
  margin: 0;
  padding: 0;
}

.multiselect-option {
  box-sizing: border-box;
  cursor: pointer;
  font-size: var(--ms-option-font-size, 1rem);
  line-height: var(--ms-option-line-height, 1.375);
  padding: var(--ms-option-py, .5rem) var(--ms-option-px, .75rem);
  text-align: left;
  justify-content: flex-start;
  align-items: center;
  text-decoration: none;
  display: flex;
}

.multiselect-option.is-pointed {
  background: var(--ms-option-bg-pointed, #f3f4f6);
  color: var(--ms-option-color-pointed, #1f2937);
}

.multiselect-option.is-selected {
  background: var(--ms-option-bg-selected, #10b981);
  color: var(--ms-option-color-selected, #fff);
}

.multiselect-option.is-disabled {
  background: var(--ms-option-bg-disabled, #fff);
  color: var(--ms-option-color-disabled, #d1d5db);
  cursor: not-allowed;
}

.multiselect-option.is-selected.is-pointed {
  background: var(--ms-option-bg-selected-pointed, #26c08e);
  color: var(--ms-option-color-selected-pointed, #fff);
}

.multiselect-option.is-selected.is-disabled {
  background: var(--ms-option-bg-selected-disabled, #87dcc0);
  color: var(--ms-option-color-selected-disabled, #d1fae5);
}

.multiselect-no-options, .multiselect-no-results {
  color: var(--ms-empty-color, #4b5563);
  padding: var(--ms-option-py, .5rem) var(--ms-option-px, .75rem);
}

.multiselect-fake-input {
  background: none;
  border: 0;
  outline: none;
  width: 100%;
  height: 1px;
  padding: 0;
  font-size: 0;
  position: absolute;
  bottom: -1px;
  left: 0;
  right: 0;
}

.multiselect-fake-input:active, .multiselect-fake-input:focus {
  outline: none;
}

.multiselect-assistive-text {
  clip: rect(0 0 0 0);
  width: 1px;
  height: 1px;
  margin: -1px;
  position: absolute;
  overflow: hidden;
}

.multiselect-spacer {
  display: none;
}

[dir="rtl"] .multiselect-multiple-label, [dir="rtl"] .multiselect-placeholder, [dir="rtl"] .multiselect-single-label {
  padding-left: calc(1.25rem + var(--ms-px, .875rem) * 3);
  padding-right: var(--ms-px, .875rem);
  left: auto;
  right: 0;
}

[dir="rtl"] .multiselect-search {
  padding-left: 0;
  padding-right: var(--ms-px, .875rem);
}

[dir="rtl"] .multiselect-tags {
  padding-left: 0;
  padding-right: var(--ms-py, .5rem);
}

[dir="rtl"] .multiselect-tag {
  margin-left: var(--ms-tag-mx, .25rem);
  padding: var(--ms-tag-py, .125rem) var(--ms-tag-px, .5rem) var(--ms-tag-py, .125rem) 0;
  margin-right: 0;
}

[dir="rtl"] .multiselect-tag.is-disabled {
  padding-left: var(--ms-tag-px, .5rem);
}

[dir="rtl"] .multiselect-caret, [dir="rtl"] .multiselect-spinner {
  margin: 0 0 0 var(--ms-px, .875rem);
}

[dir="rtl"] .multiselect-clear {
  padding: 0 0 0 var(--ms-px, .875rem);
}

@keyframes multiselect-spin {
  0% {
    transform: rotate(0);
  }

  to {
    transform: rotate(1turn);
  }
}

:root {
  --vc-clr-primary: #000;
  --vc-clr-secondary: #090f207f;
  --vc-clr-white: #fff;
  --vc-icn-width: 1.2em;
  --vc-nav-width: 30px;
  --vc-nav-height: 30px;
  --vc-nav-border-radius: 0;
  --vc-nav-color: var(--vc-clr-primary);
  --vc-nav-color-hover: var(--vc-clr-secondary);
  --vc-nav-background: transparent;
  --vc-pgn-width: 12px;
  --vc-pgn-height: 4px;
  --vc-pgn-margin: 4px;
  --vc-pgn-border-radius: 0;
  --vc-pgn-background-color: var(--vc-clr-secondary);
  --vc-pgn-active-color: var(--vc-clr-primary);
}

.carousel__prev, .carousel__next {
  box-sizing: content-box;
  background: var(--vc-nav-background);
  border-radius: var(--vc-nav-border-radius);
  width: var(--vc-nav-width);
  height: var(--vc-nav-height);
  text-align: center;
  font-size: var(--vc-nav-height);
  color: var(--vc-nav-color);
  cursor: pointer;
  border: 0;
  justify-content: center;
  align-items: center;
  margin: 0 10px;
  padding: 0;
  display: flex;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.carousel__prev:hover, .carousel__next:hover {
  color: var(--vc-nav-color-hover);
}

.carousel__next--disabled, .carousel__prev--disabled {
  cursor: not-allowed;
  opacity: .5;
}

.carousel__prev {
  left: 0;
}

.carousel__next {
  right: 0;
}

.carousel--rtl .carousel__prev {
  left: auto;
  right: 0;
}

.carousel--rtl .carousel__next {
  left: 0;
  right: auto;
}

.carousel {
  text-align: center;
  box-sizing: border-box;
  touch-action: pan-y;
  overscroll-behavior: none;
  position: relative;
}

.carousel.is-dragging {
  touch-action: none;
}

.carousel * {
  box-sizing: border-box;
}

.carousel__track {
  display: flex;
  position: relative;
  padding: 0 !important;
}

.carousel__viewport {
  overflow: hidden;
}

.carousel__sr-only {
  clip: rect(0, 0, 0, 0);
  border: 0;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  position: absolute;
  overflow: hidden;
}

.carousel__icon {
  width: var(--vc-icn-width);
  height: var(--vc-icn-width);
  fill: currentColor;
}

.carousel__pagination {
  justify-content: center;
  margin: 10px 0 0;
  line-height: 0;
  list-style: none;
  display: flex;
}

.carousel__pagination-button {
  cursor: pointer;
  padding: var(--vc-pgn-margin);
  background: none;
  border: 0;
  margin: 0;
  display: block;
}

.carousel__pagination-button:after {
  content: "";
  width: var(--vc-pgn-width);
  height: var(--vc-pgn-height);
  border-radius: var(--vc-pgn-border-radius);
  background-color: var(--vc-pgn-background-color);
  display: block;
}

.carousel__pagination-button:hover:after, .carousel__pagination-button--active:after {
  background-color: var(--vc-pgn-active-color);
}

.carousel__slide {
  scroll-snap-stop: auto;
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
  margin: 0;
  display: flex;
  position: relative;
  transform: translateZ(0);
}

.slider-target, .slider-target * {
  -webkit-touch-callout: none;
  -webkit-tap-highlight-color: #0000;
  box-sizing: border-box;
  touch-action: none;
  -webkit-user-select: none;
  user-select: none;
}

.slider-target {
  position: relative;
}

.slider-base, .slider-connects {
  z-index: 1;
  width: 100%;
  height: 100%;
  position: relative;
}

.slider-connects {
  z-index: 0;
  overflow: hidden;
}

.slider-connect, .slider-origin {
  transform-origin: 0 0;
  -webkit-transform-style: preserve-3d;
  transform-style: flat;
  will-change: transform;
  z-index: 1;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;
}

.slider-txt-dir-rtl.slider-horizontal .slider-origin {
  left: 0;
  right: auto;
}

.slider-vertical .slider-origin {
  width: 0;
  top: -100%;
}

.slider-horizontal .slider-origin {
  height: 0;
}

.slider-handle {
  backface-visibility: hidden;
  position: absolute;
}

.slider-touch-area {
  width: 100%;
  height: 100%;
}

.slider-state-tap .slider-connect, .slider-state-tap .slider-origin {
  transition: transform .3s;
}

.slider-state-drag * {
  cursor: inherit !important;
}

.slider-tooltip-drag .slider-tooltip, .slider-tooltip-focus .slider-tooltip {
  display: none !important;
}

.slider-tooltip-drag .slider-active .slider-tooltip, .slider-tooltip-drag.slider-state-drag .slider-tooltip:not(.slider-tooltip-hidden), .slider-tooltip-focus.slider-focused .slider-tooltip:not(.slider-tooltip-hidden) {
  display: block !important;
}

.slider-horizontal {
  height: var(--slider-height, 6px);
}

.slider-horizontal .slider-handle {
  height: var(--slider-handle-height, 16px);
  right: calc(var(--slider-handle-width, 16px) / 2 * -1);
  top: calc((var(--slider-handle-height, 16px)  - var(--slider-height, 6px)) / 2 * -1 + -1px);
  width: var(--slider-handle-width, 16px);
}

.slider-vertical {
  height: var(--slider-vertical-height, 300px);
  width: var(--slider-height, 6px);
}

.slider-vertical .slider-handle {
  bottom: calc(var(--slider-handle-width, 16px) / 2 * -1);
  height: var(--slider-handle-width, 16px);
  right: calc((var(--slider-handle-height, 16px)  - var(--slider-height, 6px)) / 2 * -1 + -1px);
  width: var(--slider-handle-height, 16px);
}

.slider-txt-dir-rtl.slider-horizontal .slider-handle {
  left: calc(var(--slider-handle-width, 16px) / 2 * -1);
  right: auto;
}

.slider-base {
  background-color: var(--slider-bg, #d1d5db);
}

.slider-base, .slider-connects {
  border-radius: var(--slider-radius, 9999px);
}

.slider-connect {
  background: var(--slider-connect-bg, #10b981);
  cursor: pointer;
}

.slider-draggable {
  cursor: ew-resize;
}

.slider-vertical .slider-draggable {
  cursor: ns-resize;
}

.slider-handle {
  background: var(--slider-handle-bg, #fff);
  border: var(--slider-handle-border, 0);
  border-radius: var(--slider-handle-radius, 9999px);
  box-shadow: var(--slider-handle-shadow, .5px .5px 2px 1px #00000052);
  cursor: -webkit-grab;
  cursor: grab;
  height: var(--slider-handle-height, 16px);
  width: var(--slider-handle-width, 16px);
}

.slider-handle:focus {
  box-shadow: 0 0 0 var(--slider-handle-ring-width, 3px) var(--slider-handle-ring-color, #10b98130), var(--slider-handle-shadow, .5px .5px 2px 1px #00000052);
  outline: none;
}

.slider-active {
  box-shadow: var(--slider-handle-shadow-active, .5px .5px 2px 1px #0000006b);
  cursor: -webkit-grabbing;
  cursor: grabbing;
}

[disabled] .slider-connect {
  background: var(--slider-connect-bg-disabled, #9ca3af);
}

[disabled] .slider-handle, [disabled].slider-handle, [disabled].slider-target {
  cursor: not-allowed;
}

[disabled] .slider-tooltip {
  background: var(--slider-tooltip-bg-disabled, #9ca3af);
  border-color: var(--slider-tooltip-bg-disabled, #9ca3af);
}

.slider-tooltip {
  background: var(--slider-tooltip-bg, #10b981);
  border: 1px solid var(--slider-tooltip-bg, #10b981);
  border-radius: var(--slider-tooltip-radius, 5px);
  color: var(--slider-tooltip-color, #fff);
  font-size: var(--slider-tooltip-font-size, .875rem);
  font-weight: var(--slider-tooltip-font-weight, 600);
  line-height: var(--slider-tooltip-line-height, 1.25rem);
  min-width: var(--slider-tooltip-min-width, 20px);
  padding: var(--slider-tooltip-py, 2px) var(--slider-tooltip-px, 6px);
  text-align: center;
  white-space: nowrap;
  display: block;
  position: absolute;
}

.slider-horizontal .slider-tooltip-top {
  bottom: calc(var(--slider-handle-height, 16px)  + var(--slider-tooltip-arrow-size, 5px)  + var(--slider-tooltip-distance, 3px));
  left: 50%;
  transform: translate(-50%);
}

.slider-horizontal .slider-tooltip-top:before {
  border: var(--slider-tooltip-arrow-size, 5px) solid transparent;
  border-top-color: inherit;
  bottom: calc(var(--slider-tooltip-arrow-size, 5px) * -2);
  content: "";
  width: 0;
  height: 0;
  position: absolute;
  left: 50%;
  transform: translate(-50%);
}

.slider-horizontal .slider-tooltip-bottom {
  left: 50%;
  top: calc(var(--slider-handle-height, 16px)  + var(--slider-tooltip-arrow-size, 5px)  + var(--slider-tooltip-distance, 3px));
  transform: translate(-50%);
}

.slider-horizontal .slider-tooltip-bottom:before {
  border: var(--slider-tooltip-arrow-size, 5px) solid transparent;
  border-bottom-color: inherit;
  content: "";
  left: 50%;
  top: calc(var(--slider-tooltip-arrow-size, 5px) * -2);
  width: 0;
  height: 0;
  position: absolute;
  transform: translate(-50%);
}

.slider-vertical .slider-tooltip-left {
  right: calc(var(--slider-handle-height, 16px)  + var(--slider-tooltip-arrow-size, 5px)  + var(--slider-tooltip-distance, 3px));
  top: 50%;
  transform: translateY(-50%);
}

.slider-vertical .slider-tooltip-left:before {
  border: var(--slider-tooltip-arrow-size, 5px) solid transparent;
  border-left-color: inherit;
  content: "";
  right: calc(var(--slider-tooltip-arrow-size, 5px) * -2);
  width: 0;
  height: 0;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.slider-vertical .slider-tooltip-right {
  left: calc(var(--slider-handle-height, 16px)  + var(--slider-tooltip-arrow-size, 5px)  + var(--slider-tooltip-distance, 3px));
  top: 50%;
  transform: translateY(-50%);
}

.slider-vertical .slider-tooltip-right:before {
  border: var(--slider-tooltip-arrow-size, 5px) solid transparent;
  border-right-color: inherit;
  content: "";
  left: calc(var(--slider-tooltip-arrow-size, 5px) * -2);
  width: 0;
  height: 0;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.slider-horizontal .slider-origin > .slider-tooltip {
  left: auto;
  transform: translate(50%);
}

.slider-horizontal .slider-origin > .slider-tooltip-top {
  bottom: calc(var(--slider-tooltip-arrow-size, 5px)  + (var(--slider-handle-height, 16px)  - var(--slider-height, 6px)) / 2 + var(--slider-tooltip-distance, 3px)  + 1px);
}

.slider-horizontal .slider-origin > .slider-tooltip-bottom {
  top: calc(var(--slider-tooltip-arrow-size, 5px)  + (var(--slider-handle-height, 16px)  - var(--slider-height, 6px)) / 2 + var(--slider-tooltip-distance, 3px)  + var(--slider-height, 6px)  - 1px);
}

.slider-vertical .slider-origin > .slider-tooltip {
  transform: translateY(calc((var(--slider-tooltip-line-height, 1.25rem)  - var(--slider-tooltip-py, 2px)) * -1 + 1px));
  top: auto;
}

.slider-vertical .slider-origin > .slider-tooltip-left {
  right: calc(var(--slider-tooltip-arrow-size, 5px)  + var(--slider-height, 6px)  + (var(--slider-handle-height, 16px)  - var(--slider-height, 6px)) / 2 + var(--slider-tooltip-distance, 3px)  - 1px);
}

.slider-vertical .slider-origin > .slider-tooltip-right {
  left: calc(var(--slider-tooltip-arrow-size, 5px)  + var(--slider-height, 6px)  + (var(--slider-handle-height, 16px)  - var(--slider-height, 6px)) / 2 + var(--slider-tooltip-distance, 3px)  - var(--slider-height, 6px)  + 1px);
}

.multiselect {
  background: var(--ms-bg, #fff);
  border: var(--ms-border-width, 1px) solid var(--ms-border-color, #d1d5db);
  border-radius: var(--ms-radius, 4px);
  box-sizing: border-box;
  cursor: pointer;
  font-size: var(--ms-font-size, 1rem);
  min-height: calc(var(--ms-border-width, 1px) * 2 + var(--ms-font-size, 1rem) * var(--ms-line-height, 1.375)  + var(--ms-py, .5rem) * 2);
  outline: none;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  margin: 0 auto;
  display: flex;
  position: relative;
}

.multiselect.is-open {
  border-radius: var(--ms-radius, 4px) var(--ms-radius, 4px) 0 0;
}

.multiselect.is-open-top {
  border-radius: 0 0 var(--ms-radius, 4px) var(--ms-radius, 4px);
}

.multiselect.is-disabled {
  background: var(--ms-bg-disabled, #f3f4f6);
  cursor: default;
}

.multiselect.is-active {
  border: var(--ms-border-width-active, var(--ms-border-width, 1px)) solid var(--ms-border-color-active, var(--ms-border-color, #d1d5db));
  box-shadow: 0 0 0 var(--ms-ring-width, 3px) var(--ms-ring-color, #10b98130);
}

.multiselect-wrapper {
  box-sizing: border-box;
  cursor: pointer;
  min-height: calc(var(--ms-border-width, 1px) * 2 + var(--ms-font-size, 1rem) * var(--ms-line-height, 1.375)  + var(--ms-py, .5rem) * 2);
  outline: none;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  margin: 0 auto;
  display: flex;
  position: relative;
}

.multiselect-multiple-label, .multiselect-placeholder, .multiselect-single-label {
  box-sizing: border-box;
  line-height: var(--ms-line-height, 1.375);
  padding-left: var(--ms-px, .875rem);
  padding-right: calc(1.25rem + var(--ms-px, .875rem) * 3);
  pointer-events: none;
  background: none;
  align-items: center;
  max-width: 100%;
  height: 100%;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
}

.multiselect-placeholder {
  color: var(--ms-placeholder-color, #9ca3af);
}

.multiselect-single-label-text {
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 100%;
  display: block;
  overflow: hidden;
}

.multiselect-search {
  appearance: none;
  background: var(--ms-bg, #fff);
  border-radius: var(--ms-radius, 4px);
  box-sizing: border-box;
  font-family: inherit;
  font-size: inherit;
  padding-left: var(--ms-px, .875rem);
  border: 0;
  outline: none;
  width: 100%;
  height: 100%;
  position: absolute;
  inset: 0;
}

.multiselect-search::-webkit-search-cancel-button {
  -webkit-appearance: none;
}

.multiselect-search::-webkit-search-decoration {
  -webkit-appearance: none;
}

.multiselect-search::-webkit-search-results-button {
  -webkit-appearance: none;
}

.multiselect-search::-webkit-search-results-decoration {
  -webkit-appearance: none;
}

.multiselect-tags {
  margin: var(--ms-tag-my, .25rem) 0 0;
  padding-left: var(--ms-py, .5rem);
  flex-wrap: wrap;
  flex-grow: 1;
  flex-shrink: 1;
}

.multiselect-tag, .multiselect-tags {
  align-items: center;
  min-width: 0;
  display: flex;
}

.multiselect-tag {
  background: var(--ms-tag-bg, #10b981);
  border-radius: var(--ms-tag-radius, 4px);
  color: var(--ms-tag-color, #fff);
  font-size: var(--ms-tag-font-size, .875rem);
  font-weight: var(--ms-tag-font-weight, 600);
  line-height: var(--ms-tag-line-height, 1.25rem);
  margin-bottom: var(--ms-tag-my, .25rem);
  margin-right: var(--ms-tag-mx, .25rem);
  padding: var(--ms-tag-py, .125rem) 0 var(--ms-tag-py, .125rem) var(--ms-tag-px, .5rem);
  white-space: nowrap;
}

.multiselect-tag.is-disabled {
  background: var(--ms-tag-bg-disabled, #9ca3af);
  color: var(--ms-tag-color-disabled, #fff);
  padding-right: var(--ms-tag-px, .5rem);
}

.multiselect-tag-wrapper {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.multiselect-tag-wrapper-break {
  white-space: normal;
  word-break: break-all;
}

.multiselect-tag-remove {
  border-radius: var(--ms-tag-remove-radius, 4px);
  margin: var(--ms-tag-remove-my, 0) var(--ms-tag-remove-mx, .125rem);
  padding: var(--ms-tag-remove-py, .25rem) var(--ms-tag-remove-px, .25rem);
  justify-content: center;
  align-items: center;
  display: flex;
}

.multiselect-tag-remove:hover {
  background: #00000010;
}

.multiselect-tag-remove-icon {
  opacity: .8;
  background-color: currentColor;
  width: .75rem;
  height: .75rem;
  display: inline-block;
  -webkit-mask-image: url("data:image/svg+xml;charset=utf-8,%3Csvg viewBox='0 0 320 512' fill='currentColor' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='m207.6 256 107.72-107.72c6.23-6.23 6.23-16.34 0-22.58l-25.03-25.03c-6.23-6.23-16.34-6.23-22.58 0L160 208.4 52.28 100.68c-6.23-6.23-16.34-6.23-22.58 0L4.68 125.7c-6.23 6.23-6.23 16.34 0 22.58L112.4 256 4.68 363.72c-6.23 6.23-6.23 16.34 0 22.58l25.03 25.03c6.23 6.23 16.34 6.23 22.58 0L160 303.6l107.72 107.72c6.23 6.23 16.34 6.23 22.58 0l25.03-25.03c6.23-6.23 6.23-16.34 0-22.58L207.6 256z'/%3E%3C/svg%3E");
  mask-image: url("data:image/svg+xml;charset=utf-8,%3Csvg viewBox='0 0 320 512' fill='currentColor' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='m207.6 256 107.72-107.72c6.23-6.23 6.23-16.34 0-22.58l-25.03-25.03c-6.23-6.23-16.34-6.23-22.58 0L160 208.4 52.28 100.68c-6.23-6.23-16.34-6.23-22.58 0L4.68 125.7c-6.23 6.23-6.23 16.34 0 22.58L112.4 256 4.68 363.72c-6.23 6.23-6.23 16.34 0 22.58l25.03 25.03c6.23 6.23 16.34 6.23 22.58 0L160 303.6l107.72 107.72c6.23 6.23 16.34 6.23 22.58 0l25.03-25.03c6.23-6.23 6.23-16.34 0-22.58L207.6 256z'/%3E%3C/svg%3E");
  -webkit-mask-position: center;
  mask-position: center;
  -webkit-mask-size: contain;
  mask-size: contain;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
}

.multiselect-tags-search-wrapper {
  margin: 0 var(--ms-tag-mx, 4px) var(--ms-tag-my, 4px);
  flex-grow: 1;
  flex-shrink: 1;
  height: 100%;
  display: inline-block;
  position: relative;
}

.multiselect-tags-search-copy {
  visibility: hidden;
  white-space: pre-wrap;
  width: 100%;
  height: 1px;
  display: inline-block;
}

.multiselect-tags-search {
  appearance: none;
  box-sizing: border-box;
  font-family: inherit;
  font-size: inherit;
  border: 0;
  outline: none;
  width: 100%;
  padding: 0;
  position: absolute;
  inset: 0;
}

.multiselect-tags-search::-webkit-search-cancel-button {
  -webkit-appearance: none;
}

.multiselect-tags-search::-webkit-search-decoration {
  -webkit-appearance: none;
}

.multiselect-tags-search::-webkit-search-results-button {
  -webkit-appearance: none;
}

.multiselect-tags-search::-webkit-search-results-decoration {
  -webkit-appearance: none;
}

.multiselect-inifite {
  min-height: calc(var(--ms-border-width, 1px) * 2 + var(--ms-font-size, 1rem) * var(--ms-line-height, 1.375)  + var(--ms-py, .5rem) * 2);
  justify-content: center;
  align-items: center;
  width: 100%;
  display: flex;
}

.multiselect-inifite-spinner, .multiselect-spinner {
  background-color: var(--ms-spinner-color, #10b981);
  z-index: 10;
  flex-grow: 0;
  flex-shrink: 0;
  width: 1rem;
  height: 1rem;
  animation: 1s linear infinite multiselect-spin;
  -webkit-mask-image: url("data:image/svg+xml;charset=utf-8,%3Csvg viewBox='0 0 512 512' fill='currentColor' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='m456.433 371.72-27.79-16.045c-7.192-4.152-10.052-13.136-6.487-20.636 25.82-54.328 23.566-118.602-6.768-171.03-30.265-52.529-84.802-86.621-144.76-91.424C262.35 71.922 256 64.953 256 56.649V24.56c0-9.31 7.916-16.609 17.204-15.96 81.795 5.717 156.412 51.902 197.611 123.408 41.301 71.385 43.99 159.096 8.042 232.792-4.082 8.369-14.361 11.575-22.424 6.92z'/%3E%3C/svg%3E");
  mask-image: url("data:image/svg+xml;charset=utf-8,%3Csvg viewBox='0 0 512 512' fill='currentColor' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='m456.433 371.72-27.79-16.045c-7.192-4.152-10.052-13.136-6.487-20.636 25.82-54.328 23.566-118.602-6.768-171.03-30.265-52.529-84.802-86.621-144.76-91.424C262.35 71.922 256 64.953 256 56.649V24.56c0-9.31 7.916-16.609 17.204-15.96 81.795 5.717 156.412 51.902 197.611 123.408 41.301 71.385 43.99 159.096 8.042 232.792-4.082 8.369-14.361 11.575-22.424 6.92z'/%3E%3C/svg%3E");
  -webkit-mask-position: center;
  mask-position: center;
  -webkit-mask-size: contain;
  mask-size: contain;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
}

.multiselect-spinner {
  margin: 0 var(--ms-px, .875rem) 0 0;
}

.multiselect-clear {
  opacity: 1;
  padding: 0 var(--ms-px, .875rem) 0 0;
  z-index: 10;
  flex-grow: 0;
  flex-shrink: 0;
  transition: all .3s;
  display: flex;
  position: relative;
}

.multiselect-clear:hover .multiselect-clear-icon {
  background-color: var(--ms-clear-color-hover, #000);
}

.multiselect-clear-icon {
  background-color: var(--ms-clear-color, #999);
  transition: all .3s;
  display: inline-block;
  -webkit-mask-image: url("data:image/svg+xml;charset=utf-8,%3Csvg viewBox='0 0 320 512' fill='currentColor' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='m207.6 256 107.72-107.72c6.23-6.23 6.23-16.34 0-22.58l-25.03-25.03c-6.23-6.23-16.34-6.23-22.58 0L160 208.4 52.28 100.68c-6.23-6.23-16.34-6.23-22.58 0L4.68 125.7c-6.23 6.23-6.23 16.34 0 22.58L112.4 256 4.68 363.72c-6.23 6.23-6.23 16.34 0 22.58l25.03 25.03c6.23 6.23 16.34 6.23 22.58 0L160 303.6l107.72 107.72c6.23 6.23 16.34 6.23 22.58 0l25.03-25.03c6.23-6.23 6.23-16.34 0-22.58L207.6 256z'/%3E%3C/svg%3E");
  mask-image: url("data:image/svg+xml;charset=utf-8,%3Csvg viewBox='0 0 320 512' fill='currentColor' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='m207.6 256 107.72-107.72c6.23-6.23 6.23-16.34 0-22.58l-25.03-25.03c-6.23-6.23-16.34-6.23-22.58 0L160 208.4 52.28 100.68c-6.23-6.23-16.34-6.23-22.58 0L4.68 125.7c-6.23 6.23-6.23 16.34 0 22.58L112.4 256 4.68 363.72c-6.23 6.23-6.23 16.34 0 22.58l25.03 25.03c6.23 6.23 16.34 6.23 22.58 0L160 303.6l107.72 107.72c6.23 6.23 16.34 6.23 22.58 0l25.03-25.03c6.23-6.23 6.23-16.34 0-22.58L207.6 256z'/%3E%3C/svg%3E");
}

.multiselect-caret, .multiselect-clear-icon {
  width: .625rem;
  height: 1.125rem;
  -webkit-mask-position: center;
  mask-position: center;
  -webkit-mask-size: contain;
  mask-size: contain;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
}

.multiselect-caret {
  background-color: var(--ms-caret-color, #999);
  margin: 0 var(--ms-px, .875rem) 0 0;
  pointer-events: none;
  z-index: 10;
  flex-grow: 0;
  flex-shrink: 0;
  transition: transform .3s;
  position: relative;
  transform: rotate(0);
  -webkit-mask-image: url("data:image/svg+xml;charset=utf-8,%3Csvg viewBox='0 0 320 512' fill='currentColor' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M31.3 192h257.3c17.8 0 26.7 21.5 14.1 34.1L174.1 354.8c-7.8 7.8-20.5 7.8-28.3 0L17.2 226.1C4.6 213.5 13.5 192 31.3 192z'/%3E%3C/svg%3E");
  mask-image: url("data:image/svg+xml;charset=utf-8,%3Csvg viewBox='0 0 320 512' fill='currentColor' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M31.3 192h257.3c17.8 0 26.7 21.5 14.1 34.1L174.1 354.8c-7.8 7.8-20.5 7.8-28.3 0L17.2 226.1C4.6 213.5 13.5 192 31.3 192z'/%3E%3C/svg%3E");
}

.multiselect-caret.is-open {
  pointer-events: auto;
  transform: rotate(180deg);
}

.multiselect-dropdown {
  -webkit-overflow-scrolling: touch;
  background: var(--ms-dropdown-bg, #fff);
  border: var(--ms-dropdown-border-width, 1px) solid var(--ms-dropdown-border-color, #d1d5db);
  border-radius: 0 0 var(--ms-dropdown-radius, 4px) var(--ms-dropdown-radius, 4px);
  bottom: 0;
  left: calc(var(--ms-border-width, 1px) * -1);
  margin-top: calc(var(--ms-border-width, 1px) * -1);
  max-height: var(--ms-max-height, 10rem);
  right: calc(var(--ms-border-width, 1px) * -1);
  z-index: 100;
  outline: none;
  flex-direction: column;
  display: flex;
  position: absolute;
  overflow-y: scroll;
  transform: translateY(100%);
}

.multiselect-dropdown.is-top {
  border-radius: var(--ms-dropdown-radius, 4px) var(--ms-dropdown-radius, 4px) 0 0;
  bottom: auto;
  top: var(--ms-border-width, 1px);
  transform: translateY(-100%);
}

.multiselect-dropdown.is-hidden {
  display: none;
}

.multiselect-options {
  flex-direction: column;
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
}

.multiselect-group {
  margin: 0;
  padding: 0;
}

.multiselect-group-label {
  background: var(--ms-group-label-bg, #e5e7eb);
  box-sizing: border-box;
  color: var(--ms-group-label-color, #374151);
  cursor: default;
  font-size: .875rem;
  font-weight: 600;
  line-height: var(--ms-group-label-line-height, 1.375);
  padding: var(--ms-group-label-py, .3rem) var(--ms-group-label-px, .75rem);
  text-align: left;
  justify-content: flex-start;
  align-items: center;
  text-decoration: none;
  display: flex;
}

.multiselect-group-label.is-pointable {
  cursor: pointer;
}

.multiselect-group-label.is-pointed {
  background: var(--ms-group-label-bg-pointed, #d1d5db);
  color: var(--ms-group-label-color-pointed, #374151);
}

.multiselect-group-label.is-selected {
  background: var(--ms-group-label-bg-selected, #059669);
  color: var(--ms-group-label-color-selected, #fff);
}

.multiselect-group-label.is-disabled {
  background: var(--ms-group-label-bg-disabled, #f3f4f6);
  color: var(--ms-group-label-color-disabled, #d1d5db);
  cursor: not-allowed;
}

.multiselect-group-label.is-selected.is-pointed {
  background: var(--ms-group-label-bg-selected-pointed, #0c9e70);
  color: var(--ms-group-label-color-selected-pointed, #fff);
}

.multiselect-group-label.is-selected.is-disabled {
  background: var(--ms-group-label-bg-selected-disabled, #75cfb1);
  color: var(--ms-group-label-color-selected-disabled, #d1fae5);
}

.multiselect-group-options {
  margin: 0;
  padding: 0;
}

.multiselect-option {
  box-sizing: border-box;
  cursor: pointer;
  font-size: var(--ms-option-font-size, 1rem);
  line-height: var(--ms-option-line-height, 1.375);
  padding: var(--ms-option-py, .5rem) var(--ms-option-px, .75rem);
  text-align: left;
  justify-content: flex-start;
  align-items: center;
  text-decoration: none;
  display: flex;
}

.multiselect-option.is-pointed {
  background: var(--ms-option-bg-pointed, #f3f4f6);
  color: var(--ms-option-color-pointed, #1f2937);
}

.multiselect-option.is-selected {
  background: var(--ms-option-bg-selected, #10b981);
  color: var(--ms-option-color-selected, #fff);
}

.multiselect-option.is-disabled {
  background: var(--ms-option-bg-disabled, #fff);
  color: var(--ms-option-color-disabled, #d1d5db);
  cursor: not-allowed;
}

.multiselect-option.is-selected.is-pointed {
  background: var(--ms-option-bg-selected-pointed, #26c08e);
  color: var(--ms-option-color-selected-pointed, #fff);
}

.multiselect-option.is-selected.is-disabled {
  background: var(--ms-option-bg-selected-disabled, #87dcc0);
  color: var(--ms-option-color-selected-disabled, #d1fae5);
}

.multiselect-no-options, .multiselect-no-results {
  color: var(--ms-empty-color, #4b5563);
  padding: var(--ms-option-py, .5rem) var(--ms-option-px, .75rem);
}

.multiselect-fake-input {
  background: none;
  border: 0;
  outline: none;
  width: 100%;
  height: 1px;
  padding: 0;
  font-size: 0;
  position: absolute;
  bottom: -1px;
  left: 0;
  right: 0;
}

.multiselect-fake-input:active, .multiselect-fake-input:focus {
  outline: none;
}

.multiselect-assistive-text {
  clip: rect(0 0 0 0);
  width: 1px;
  height: 1px;
  margin: -1px;
  position: absolute;
  overflow: hidden;
}

.multiselect-spacer {
  display: none;
}

[dir="rtl"] .multiselect-multiple-label, [dir="rtl"] .multiselect-placeholder, [dir="rtl"] .multiselect-single-label {
  padding-left: calc(1.25rem + var(--ms-px, .875rem) * 3);
  padding-right: var(--ms-px, .875rem);
  left: auto;
  right: 0;
}

[dir="rtl"] .multiselect-search {
  padding-left: 0;
  padding-right: var(--ms-px, .875rem);
}

[dir="rtl"] .multiselect-tags {
  padding-left: 0;
  padding-right: var(--ms-py, .5rem);
}

[dir="rtl"] .multiselect-tag {
  margin-left: var(--ms-tag-mx, .25rem);
  padding: var(--ms-tag-py, .125rem) var(--ms-tag-px, .5rem) var(--ms-tag-py, .125rem) 0;
  margin-right: 0;
}

[dir="rtl"] .multiselect-tag.is-disabled {
  padding-left: var(--ms-tag-px, .5rem);
}

[dir="rtl"] .multiselect-caret, [dir="rtl"] .multiselect-spinner {
  margin: 0 0 0 var(--ms-px, .875rem);
}

[dir="rtl"] .multiselect-clear {
  padding: 0 0 0 var(--ms-px, .875rem);
}

@keyframes multiselect-spin {
  0% {
    transform: rotate(0);
  }

  to {
    transform: rotate(1turn);
  }
}

.leaflet-pane, .leaflet-tile, .leaflet-marker-icon, .leaflet-marker-shadow, .leaflet-tile-container, .leaflet-pane > svg, .leaflet-pane > canvas, .leaflet-zoom-box, .leaflet-image-layer, .leaflet-layer {
  position: absolute;
  top: 0;
  left: 0;
}

.leaflet-container {
  overflow: hidden;
}

.leaflet-tile, .leaflet-marker-icon, .leaflet-marker-shadow {
  -webkit-user-select: none;
  user-select: none;
  -webkit-user-drag: none;
}

.leaflet-tile::selection {
  background: none;
}

.leaflet-safari .leaflet-tile {
  image-rendering: -webkit-optimize-contrast;
}

.leaflet-safari .leaflet-tile-container {
  -webkit-transform-origin: 0 0;
  width: 1600px;
  height: 1600px;
}

.leaflet-marker-icon, .leaflet-marker-shadow {
  display: block;
}

.leaflet-container .leaflet-overlay-pane svg {
  max-width: none !important;
  max-height: none !important;
}

.leaflet-container .leaflet-marker-pane img, .leaflet-container .leaflet-shadow-pane img, .leaflet-container .leaflet-tile-pane img, .leaflet-container img.leaflet-image-layer, .leaflet-container .leaflet-tile {
  width: auto;
  padding: 0;
  max-width: none !important;
  max-height: none !important;
}

.leaflet-container img.leaflet-tile {
  mix-blend-mode: plus-lighter;
}

.leaflet-container.leaflet-touch-zoom {
  -ms-touch-action: pan-x pan-y;
  touch-action: pan-x pan-y;
}

.leaflet-container.leaflet-touch-drag {
  -ms-touch-action: pinch-zoom;
  touch-action: none;
  touch-action: pinch-zoom;
}

.leaflet-container.leaflet-touch-drag.leaflet-touch-zoom {
  -ms-touch-action: none;
  touch-action: none;
}

.leaflet-container {
  -webkit-tap-highlight-color: transparent;
}

.leaflet-container a {
  -webkit-tap-highlight-color: #33b5e566;
}

.leaflet-tile {
  filter: inherit;
  visibility: hidden;
}

.leaflet-tile-loaded {
  visibility: inherit;
}

.leaflet-zoom-box {
  box-sizing: border-box;
  z-index: 800;
  width: 0;
  height: 0;
}

.leaflet-overlay-pane svg {
  -moz-user-select: none;
}

.leaflet-pane {
  z-index: 400;
}

.leaflet-tile-pane {
  z-index: 200;
}

.leaflet-overlay-pane {
  z-index: 400;
}

.leaflet-shadow-pane {
  z-index: 500;
}

.leaflet-marker-pane {
  z-index: 600;
}

.leaflet-tooltip-pane {
  z-index: 650;
}

.leaflet-popup-pane {
  z-index: 700;
}

.leaflet-map-pane canvas {
  z-index: 100;
}

.leaflet-map-pane svg {
  z-index: 200;
}

.leaflet-vml-shape {
  width: 1px;
  height: 1px;
}

.lvml {
  behavior: url("#default#VML");
  display: inline-block;
  position: absolute;
}

.leaflet-control {
  z-index: 800;
  pointer-events: visiblePainted;
  pointer-events: auto;
  position: relative;
}

.leaflet-top, .leaflet-bottom {
  z-index: 1000;
  pointer-events: none;
  position: absolute;
}

.leaflet-top {
  top: 0;
}

.leaflet-right {
  right: 0;
}

.leaflet-bottom {
  bottom: 0;
}

.leaflet-left {
  left: 0;
}

.leaflet-control {
  float: left;
  clear: both;
}

.leaflet-right .leaflet-control {
  float: right;
}

.leaflet-top .leaflet-control {
  margin-top: 10px;
}

.leaflet-bottom .leaflet-control {
  margin-bottom: 10px;
}

.leaflet-left .leaflet-control {
  margin-left: 10px;
}

.leaflet-right .leaflet-control {
  margin-right: 10px;
}

.leaflet-fade-anim .leaflet-popup {
  opacity: 0;
  transition: opacity .2s linear;
}

.leaflet-fade-anim .leaflet-map-pane .leaflet-popup {
  opacity: 1;
}

.leaflet-zoom-animated {
  transform-origin: 0 0;
}

svg.leaflet-zoom-animated {
  will-change: transform;
}

.leaflet-zoom-anim .leaflet-zoom-animated {
  -webkit-transition: -webkit-transform .25s cubic-bezier(0, 0, .25, 1);
  -moz-transition: -moz-transform .25s cubic-bezier(0, 0, .25, 1);
  transition: transform .25s cubic-bezier(0, 0, .25, 1);
}

.leaflet-zoom-anim .leaflet-tile, .leaflet-pan-anim .leaflet-tile {
  transition: none;
}

.leaflet-zoom-anim .leaflet-zoom-hide {
  visibility: hidden;
}

.leaflet-interactive {
  cursor: pointer;
}

.leaflet-grab {
  cursor: -webkit-grab;
  cursor: -moz-grab;
  cursor: grab;
}

.leaflet-crosshair, .leaflet-crosshair .leaflet-interactive {
  cursor: crosshair;
}

.leaflet-popup-pane, .leaflet-control {
  cursor: auto;
}

.leaflet-dragging .leaflet-grab, .leaflet-dragging .leaflet-grab .leaflet-interactive, .leaflet-dragging .leaflet-marker-draggable {
  cursor: move;
  cursor: -webkit-grabbing;
  cursor: -moz-grabbing;
  cursor: grabbing;
}

.leaflet-marker-icon, .leaflet-marker-shadow, .leaflet-image-layer, .leaflet-pane > svg path, .leaflet-tile-container {
  pointer-events: none;
}

.leaflet-marker-icon.leaflet-interactive, .leaflet-image-layer.leaflet-interactive, .leaflet-pane > svg path.leaflet-interactive, svg.leaflet-image-layer.leaflet-interactive path {
  pointer-events: visiblePainted;
  pointer-events: auto;
}

.leaflet-container {
  outline-offset: 1px;
  background: #ddd;
}

.leaflet-container a {
  color: #0078a8;
}

.leaflet-zoom-box {
  background: #ffffff80;
  border: 2px dotted #38f;
}

.leaflet-container {
  font-family: Helvetica Neue, Arial, Helvetica, sans-serif;
  font-size: .75rem;
  line-height: 1.5;
}

.leaflet-bar {
  border-radius: 4px;
  box-shadow: 0 1px 5px #000000a6;
}

.leaflet-bar a {
  text-align: center;
  color: #000;
  background-color: #fff;
  border-bottom: 1px solid #ccc;
  width: 26px;
  height: 26px;
  line-height: 26px;
  text-decoration: none;
  display: block;
}

.leaflet-bar a, .leaflet-control-layers-toggle {
  background-position: 50%;
  background-repeat: no-repeat;
  display: block;
}

.leaflet-bar a:hover, .leaflet-bar a:focus {
  background-color: #f4f4f4;
}

.leaflet-bar a:first-child {
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}

.leaflet-bar a:last-child {
  border-bottom: none;
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
}

.leaflet-bar a.leaflet-disabled {
  cursor: default;
  color: #bbb;
  background-color: #f4f4f4;
}

.leaflet-touch .leaflet-bar a {
  width: 30px;
  height: 30px;
  line-height: 30px;
}

.leaflet-touch .leaflet-bar a:first-child {
  border-top-left-radius: 2px;
  border-top-right-radius: 2px;
}

.leaflet-touch .leaflet-bar a:last-child {
  border-bottom-right-radius: 2px;
  border-bottom-left-radius: 2px;
}

.leaflet-control-zoom-in, .leaflet-control-zoom-out {
  text-indent: 1px;
  font: bold 18px Lucida Console, Monaco, monospace;
}

.leaflet-touch .leaflet-control-zoom-in, .leaflet-touch .leaflet-control-zoom-out {
  font-size: 22px;
}

.leaflet-control-layers {
  background: #fff;
  border-radius: 5px;
  box-shadow: 0 1px 5px #0006;
}

.leaflet-control-layers-toggle {
  background-image: url("layers.760a0456.png");
  width: 36px;
  height: 36px;
}

.leaflet-retina .leaflet-control-layers-toggle {
  background-image: url("layers-2x.b7b89169.png");
  background-size: 26px 26px;
}

.leaflet-touch .leaflet-control-layers-toggle {
  width: 44px;
  height: 44px;
}

.leaflet-control-layers .leaflet-control-layers-list, .leaflet-control-layers-expanded .leaflet-control-layers-toggle {
  display: none;
}

.leaflet-control-layers-expanded .leaflet-control-layers-list {
  display: block;
  position: relative;
}

.leaflet-control-layers-expanded {
  color: #333;
  background: #fff;
  padding: 6px 10px 6px 6px;
}

.leaflet-control-layers-scrollbar {
  padding-right: 5px;
  overflow-x: hidden;
  overflow-y: scroll;
}

.leaflet-control-layers-selector {
  margin-top: 2px;
  position: relative;
  top: 1px;
}

.leaflet-control-layers label {
  font-size: 1.08333em;
  display: block;
}

.leaflet-control-layers-separator {
  border-top: 1px solid #ddd;
  height: 0;
  margin: 5px -10px 5px -6px;
}

.leaflet-default-icon-path {
  background-image: url("marker-icon.3f7d3721.png");
}

.leaflet-container .leaflet-control-attribution {
  background: #fffc;
  margin: 0;
}

.leaflet-control-attribution, .leaflet-control-scale-line {
  color: #333;
  padding: 0 5px;
  line-height: 1.4;
}

.leaflet-control-attribution a {
  text-decoration: none;
}

.leaflet-control-attribution a:hover, .leaflet-control-attribution a:focus {
  text-decoration: underline;
}

.leaflet-attribution-flag {
  width: 1em;
  height: .6669em;
  vertical-align: baseline !important;
  display: inline !important;
}

.leaflet-left .leaflet-control-scale {
  margin-left: 5px;
}

.leaflet-bottom .leaflet-control-scale {
  margin-bottom: 5px;
}

.leaflet-control-scale-line {
  white-space: nowrap;
  box-sizing: border-box;
  text-shadow: 1px 1px #fff;
  background: #fffc;
  border: 2px solid #777;
  border-top: none;
  padding: 2px 5px 1px;
  line-height: 1.1;
}

.leaflet-control-scale-line:not(:first-child) {
  border-top: 2px solid #777;
  border-bottom: none;
  margin-top: -2px;
}

.leaflet-control-scale-line:not(:first-child):not(:last-child) {
  border-bottom: 2px solid #777;
}

.leaflet-touch .leaflet-control-attribution, .leaflet-touch .leaflet-control-layers, .leaflet-touch .leaflet-bar {
  box-shadow: none;
}

.leaflet-touch .leaflet-control-layers, .leaflet-touch .leaflet-bar {
  background-clip: padding-box;
  border: 2px solid #0003;
}

.leaflet-popup {
  text-align: center;
  margin-bottom: 20px;
  position: absolute;
}

.leaflet-popup-content-wrapper {
  text-align: left;
  border-radius: 12px;
  padding: 1px;
}

.leaflet-popup-content {
  min-height: 1px;
  margin: 13px 24px 13px 20px;
  font-size: 1.08333em;
  line-height: 1.3;
}

.leaflet-popup-content p {
  margin: 1.3em 0;
}

.leaflet-popup-tip-container {
  pointer-events: none;
  width: 40px;
  height: 20px;
  margin-top: -1px;
  margin-left: -20px;
  position: absolute;
  left: 50%;
  overflow: hidden;
}

.leaflet-popup-tip {
  pointer-events: auto;
  width: 17px;
  height: 17px;
  margin: -10px auto 0;
  padding: 1px;
  transform: rotate(45deg);
}

.leaflet-popup-content-wrapper, .leaflet-popup-tip {
  color: #333;
  background: #fff;
  box-shadow: 0 3px 14px #0006;
}

.leaflet-container a.leaflet-popup-close-button {
  text-align: center;
  color: #757575;
  background: none;
  border: none;
  width: 24px;
  height: 24px;
  font: 16px / 24px Tahoma, Verdana, sans-serif;
  text-decoration: none;
  position: absolute;
  top: 0;
  right: 0;
}

.leaflet-container a.leaflet-popup-close-button:hover, .leaflet-container a.leaflet-popup-close-button:focus {
  color: #585858;
}

.leaflet-popup-scrolled {
  overflow: auto;
}

.leaflet-oldie .leaflet-popup-content-wrapper {
  -ms-zoom: 1;
}

.leaflet-oldie .leaflet-popup-tip {
  -ms-filter: "progid:DXImageTransform.Microsoft.Matrix(M11=0.70710678, M12=0.70710678, M21=-0.70710678, M22=0.70710678)";
  filter: progid:DXImageTransform. Microsoft. Matrix(M11= .707107, M12= .707107, M21= -.707107, M22= .707107);
  width: 24px;
  margin: 0 auto;
}

.leaflet-oldie .leaflet-control-zoom, .leaflet-oldie .leaflet-control-layers, .leaflet-oldie .leaflet-popup-content-wrapper, .leaflet-oldie .leaflet-popup-tip {
  border: 1px solid #999;
}

.leaflet-div-icon {
  background: #fff;
  border: 1px solid #666;
}

.leaflet-tooltip {
  color: #222;
  white-space: nowrap;
  -webkit-user-select: none;
  user-select: none;
  pointer-events: none;
  background-color: #fff;
  border: 1px solid #fff;
  border-radius: 3px;
  padding: 6px;
  position: absolute;
  box-shadow: 0 1px 3px #0006;
}

.leaflet-tooltip.leaflet-interactive {
  cursor: pointer;
  pointer-events: auto;
}

.leaflet-tooltip-top:before, .leaflet-tooltip-bottom:before, .leaflet-tooltip-left:before, .leaflet-tooltip-right:before {
  pointer-events: none;
  content: "";
  background: none;
  border: 6px solid #0000;
  position: absolute;
}

.leaflet-tooltip-bottom {
  margin-top: 6px;
}

.leaflet-tooltip-top {
  margin-top: -6px;
}

.leaflet-tooltip-bottom:before, .leaflet-tooltip-top:before {
  margin-left: -6px;
  left: 50%;
}

.leaflet-tooltip-top:before {
  border-top-color: #fff;
  margin-bottom: -12px;
  bottom: 0;
}

.leaflet-tooltip-bottom:before {
  border-bottom-color: #fff;
  margin-top: -12px;
  margin-left: -6px;
  top: 0;
}

.leaflet-tooltip-left {
  margin-left: -6px;
}

.leaflet-tooltip-right {
  margin-left: 6px;
}

.leaflet-tooltip-left:before, .leaflet-tooltip-right:before {
  margin-top: -6px;
  top: 50%;
}

.leaflet-tooltip-left:before {
  border-left-color: #fff;
  margin-right: -12px;
  right: 0;
}

.leaflet-tooltip-right:before {
  border-right-color: #fff;
  margin-left: -12px;
  left: 0;
}

@media print {
  .leaflet-control {
    -webkit-print-color-adjust: exact;
    print-color-adjust: exact;
  }
}

@keyframes v-pulseStretchDelay {
  0%, 80% {
    -webkit-opacity: 1;
    opacity: 1;
    transform: scale(1);
  }

  45% {
    -webkit-opacity: .7;
    opacity: .7;
    transform: scale(.1);
  }
}

/*# sourceMappingURL=index.02f6d88d.css.map */
